import React from "react";
import SVG from "react-inlinesvg";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { t } from "ttag";

import { templates } from "@reactivated";

import logo from "../../static/img/pillows/TempurPillows_Logo.png";
import lock from "../src/svg/lock.svg";

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    return (
        <header className="l-full-width">
            <nav className="site-nav checkout-header">
                <div className="site-nav__content checkout-header__content l-capped-width">
                    <a
                        className="site-nav__logo-link"
                        href={props.root_page_url}
                    >
                        <img
                            className="site-nav__pillows-logo site-nav__logo-mobile"
                            alt={t`TEMPUR-PILLOWS FROM TEMPUR-PEDIC®`}
                            src={logo}
                        />
                    </a>

                    <h1 className="checkout-header__header">{t`Checkout`}</h1>

                    <div className="checkout-header__secure">
                        <SVG
                            className="checkout-header__button-icon checkout-header__button-icon--lock"
                            src={lock}
                        />
                        {t`Secure`}
                    </div>

                    <div className="checkout-header__header-cart-ref">
                        <CartReferenceNumber
                            encodedBasketID={props.encoded_basket_id}
                            phoneNumber={props.support_website_phone_number}
                        />
                    </div>

                    <a
                        className="site-nav__logo-link site-nav__logo-link--mobile site-nav__logo-link--mobile-checkout"
                        href={props.root_page_url}
                    >
                        <img
                            className="site-nav__pillows-logo site-nav__logo-mobile"
                            src={logo}
                        />
                    </a>
                </div>
            </nav>
        </header>
    );
};
