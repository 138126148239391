import React from "react";
import { Provider } from "react-redux";
import { IOptionCode } from "tsi-common-react/src/models/catalogue.interfaces";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { IImageURL } from "tsi-common-react/src/models/nominals";
import { check } from "tsi-common-react/src/models/utils";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";
import * as pdpModels from "./models";

/**
 * Load CMS data and the initially selected product variant
 */
let cmsData: pdpModels.IProductPage | null = null;
let pdpHeroImage: IImageURL | null = null;
let pdpMobileHeroImage: IImageURL | null = null;
let pdpHeroAlt: string | null = null;
let pdpVariantHeroImages: pdpModels.IProductPage["variant_hero_images_with_urls"] =
    [];
let pdpRootProductDescriptions: pdpModels.IProductPage["variant_configurator_description_data"] =
    [];
const detailContainerElem = document.querySelector<HTMLDivElement>(
    "#product-detail-container[data-cms]",
);
if (detailContainerElem) {
    // Load PDP CMS data
    const dataset = JSON.parse(detailContainerElem.dataset.cms || "");
    cmsData = check(pdpModels.ProductPage.decode(dataset));
    if (cmsData) {
        pdpHeroImage = cmsData.hero_image_url;
        pdpMobileHeroImage = cmsData.mobile_hero_image_url;
        pdpHeroAlt = cmsData.hero_image_alt;
        pdpVariantHeroImages = cmsData.variant_hero_images_with_urls;
        pdpRootProductDescriptions =
            cmsData.variant_configurator_description_data;
    }
}
// Add attribute to fix inconsitent anchoring to PDP reviews
const reviewsElem = "reviews__body";
const hash = urls.getHash(reviewsElem);
if (hash) {
    onDOMContentLoaded.on(async () => {
        document.body.setAttribute("scrollToReviews", hash.toString());
    });
}
/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="modular-configurator"]',
    async (elem) => {
        const { ModularConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
        );
        const { UpsellModal } = await import("./components/UpsellModal");
        await rehydratingStore;
        return (
            <Provider store={store}>
                <ModularConfigurator
                    optionSetJSON={elem.dataset.configuratorInitData || ""}
                    passthroughProps={{
                        title: cmsData ? cmsData.title : "",
                        description: cmsData ? cmsData.description : "",
                        descriptions: pdpRootProductDescriptions
                            ? pdpRootProductDescriptions
                            : [],
                        enableHistoryInteraction: true,
                        showUpsellModal: () => {
                            return (
                                !!cmsData &&
                                !!cmsData.bundle_modal_serialized &&
                                cmsData.bundle_modal_serialized.length >= 0
                            );
                        },
                        getUpsellModalComponentClass: () => {
                            return UpsellModal;
                        },
                        buttonColor: "primary-lochmara",
                        buildAdditionalContent: () => {
                            return null;
                        },
                        configType: "pdp-hero",
                        getDeliveryCopy: () => {
                            return "";
                        },
                        showFeelScale: false,
                        showImagePerUpgradeOption: false,
                        showPreTitle: false,
                        showShippingLead: false,
                        showVariantCallout: false,
                        starRatingURL:
                            detailContainerElem &&
                            detailContainerElem.dataset.reviewsPageUrl
                                ? isoWebPageURL.wrap(
                                      detailContainerElem.dataset
                                          .reviewsPageUrl,
                                  )
                                : undefined,
                        strikeThroughMSRP: false,
                        actualPriceStyle: "",
                        boxHeaderContent: cmsData ? cmsData.box_header : "",
                        topBadgeContent: cmsData ? cmsData.top_button_text : "",
                    }}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Place PDPHero components
 */
dynamicPlaceComponent('[data-place-react="pdp-hero"]', async () => {
    const { PDPHero } = await import("./containers/PDPHero");
    return (
        <Provider store={store}>
            <PDPHero
                defaultBackgroundImage={pdpHeroImage}
                defaultMobileBackgroundImage={pdpMobileHeroImage}
                defaultImageTitle={pdpHeroAlt || ""}
                variantHeroInfo={pdpVariantHeroImages}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render Galleries
 */
dynamicPlaceComponent('[data-place-react="pdp-gallery"]', async () => {
    const { Gallery } = await import("./containers/PDPGallery");
    await readyStateComplete; // Wait for the document to completely load, then render the Gallery Component.
    return (
        <Provider store={store}>
            <Gallery />
        </Provider>
    );
}).catch(console.error);

/**
 * Render ChangeVariantOptionBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-variant-option-block"]',
    async (elem) => {
        const { PDPChangeVariantOptionBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeVariantOptionBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        return (
            <Provider store={store}>
                <PDPChangeVariantOptionBlock attributeCode={attributeCode} />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render SelectedVariantConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-variant-conditional-block"]',
    async (elem) => {
        const { PDPSelectedVariantConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedVariantConditionalBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        const attributeValue = elem.dataset.attributeValue || "";
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedVariantConditionalBlock
                    attributeCode={attributeCode}
                    attributeValue={attributeValue}
                    content={htmlContent}
                />
            </Provider>
        );
    },
).catch(console.error);
